import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/index-page.vue";
import About from "../views/about-us.vue";
import Contact from "../views/contact-us.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  {
    path: "/products/:category",
    name: "ProductsShowcase",
    component: () => import("../views/product-showcase.vue"), // Corrected to match the actual file name
    props: true, // Pass the route param as a prop to the component
  },
  {
    path: "/product/:id",
    name: "ProductDetails",
    component: () => import("../views/product-details.vue"), // Product details view
    props: true, // Pass the route param (id) as a prop to the component
  },
  {
    path: "/projects",
    name: "ProjectsPage",
    component: () => import("../views/projects-page.vue"), // Projects page view
  },
  {
    path: "/project/:id",
    name: "ProjectDetails",
    component: () => import("../views/project-details.vue"), // Project details view
    props: true, // Pass the route param (id) as a prop to the component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
