<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img
          src="/logo.png"
          alt="InitiativeCode Logo"
          width="100"
          height="35"
          class="d-inline-block align-text-top"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav ms-auto">
          <router-link class="nav-link" to="/" aria-current="page"
            >Home</router-link
          >
          <router-link class="nav-link" to="/about">About Us</router-link>
          <router-link class="nav-link" to="/projects">Projects</router-link>

          <!-- Dropdown Menu -->
          <div class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Products
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/products/communication"
                  >Communication</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/products/akis-queue"
                  >Akis Queue System</router-link
                >
              </li>
              <li class="dropdown-submenu">
                <a class="dropdown-item dropdown-toggle" href="#"
                  >Solar System</a
                >
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/products/solar-panels"
                      >Solar Panels</router-link
                    >
                  </li>
                  <li>
                    <router-link class="dropdown-item" to="/products/inverters"
                      >Inverters</router-link
                    >
                  </li>
                  <li>
                    <router-link class="dropdown-item" to="/products/batteries"
                      >Batteries</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <router-link class="nav-link" to="/contact">Contact Us</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  mounted() {
    this.enableSubmenu();
  },
  methods: {
    enableSubmenu() {
      const dropdownElements = document.querySelectorAll(".dropdown-submenu");

      dropdownElements.forEach(function (dropdown) {
        dropdown.addEventListener("mouseover", function () {
          const submenu = this.querySelector(".dropdown-menu");
          submenu.classList.add("show");
        });

        dropdown.addEventListener("mouseout", function () {
          const submenu = this.querySelector(".dropdown-menu");
          submenu.classList.remove("show");
        });
      });
    },
  },
};
</script>

<style scoped>
/* Navbar Styles */
.navbar {
  background-color: #ffffff;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease;
}

.navbar-nav .nav-link {
  color: #333333;
  padding: 0.75rem 1rem;
  transition: color 0.3s ease;
  font-weight: 500;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: #007bff;
}

.navbar-brand img {
  max-height: 40px;
}

/* Dropdown Menu */
.dropdown-menu {
  border-radius: 0.5rem;
  transition: opacity 0.3s ease;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

/* Navbar Toggler */
.navbar-toggler {
  border: none;
  outline: none;
}

@media (max-width: 992px) {
  .navbar-collapse {
    background-color: #ffffff;
    padding: 1rem;
    border-top: 1px solid #e5e5e5;
  }
}
</style>
