<template>
  <div class="contact-us-page">
    <!-- Hero Section with Overlay -->
    <div class="hero-section position-relative">
      <img src="/contact-us.png" class="w-100 hero-image" alt="Contact Us" />
      <div
        class="hero-overlay position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      >
        <h1 class="text-white fw-bold display-4">Contact Us</h1>
        <p class="text-white mt-3 fs-5">
          We are here to help you with all your inquiries
        </p>
      </div>
    </div>

    <!-- Contact Form and Info Section -->
    <div class="container my-5">
      <div class="row g-5">
        <!-- Contact Form -->
        <div class="col-lg-7">
          <div class="card p-4 shadow-sm">
            <h3 class="text-primary mb-4">Get in Touch</h3>
            <form @submit.prevent="sendEmail">
              <div class="mb-4">
                <label for="name" class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="mb-4">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div class="mb-4">
                <label for="phone" class="form-label">Phone</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  placeholder="Enter your phone number"
                />
              </div>
              <div class="mb-4">
                <label for="message" class="form-label">Message</label>
                <textarea
                  class="form-control"
                  id="message"
                  rows="5"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>
              <button type="submit" class="btn btn-primary w-100 py-2">
                Submit
              </button>
            </form>
          </div>
        </div>

        <!-- Contact Information and Working Hours -->
        <div class="col-lg-5">
          <div class="info-card p-4 shadow-sm bg-light rounded mb-5">
            <h3 class="text-primary mb-4">Contact Information</h3>
            <ul class="list-unstyled fs-5">
              <li class="mb-3">
                <i class="fa-solid fa-phone me-2 text-primary"></i>
                +970 2 2985198
              </li>
              <li class="mb-3">
                <i class="fa-solid fa-envelope me-2 text-primary"></i>
                info@excellent.ps
              </li>
              <li class="mb-3">
                <i class="fa-solid fa-map-marker-alt me-2 text-primary"></i>
                Al Falah Building, Al Quds St., Ramallah, Palestine
              </li>
            </ul>
          </div>

          <div class="info-card p-4 shadow-sm bg-light rounded">
            <h3 class="text-primary mb-4">Working Hours</h3>
            <ul class="list-unstyled fs-5 text-muted">
              <li class="d-flex justify-content-between mb-3">
                <span>Sunday:</span>
                <span>8:30 - 17:00</span>
              </li>
              <li class="d-flex justify-content-between mb-3">
                <span>Monday:</span>
                <span>8:30 - 17:00</span>
              </li>
              <li class="d-flex justify-content-between mb-3">
                <span>Tuesday:</span>
                <span>8:30 - 17:00</span>
              </li>
              <li class="d-flex justify-content-between mb-3">
                <span>Wednesday:</span>
                <span>8:30 - 17:00</span>
              </li>
              <li class="d-flex justify-content-between">
                <span>Thursday:</span>
                <span>8:30 - 17:00</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Map Section -->
    <div class="map-section py-5 bg-light">
      <div class="container">
        <h3 class="text-center text-primary mb-4">Visit Us</h3>
        <div class="mapouter rounded overflow-hidden shadow-sm">
          <div class="gmap_canvas">
            <iframe
              width="100%"
              height="400"
              src="https://maps.google.com/maps?q=31.889582312369708,35.21487158098775&hl=en&z=16&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  methods: {
    sendEmail() {
      // Add email sending logic here
      alert("Form submitted!");
    },
  },
  metaInfo: {
    title: "Contact Us | Excellent Systems",
    meta: [
      {
        name: "description",
        content:
          "Get in touch with Excellent Systems for inquiries about our power, IT, and solar energy solutions.",
      },
      {
        name: "keywords",
        content:
          "Contact Excellent Systems, Power solutions, IT services, Solar energy inquiries",
      },
      { property: "og:title", content: "Contact Us | Excellent Systems" },
      {
        property: "og:description",
        content:
          "Contact Excellent Systems to discuss our power, IT, and solar energy services.",
      },
      { property: "og:image", content: "/contact-us.jpg" },
      { property: "og:url", content: "https://yourdomain.com/contact" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: "Contact Us | Excellent Systems" },
      {
        name: "twitter:description",
        content:
          "Reach out to us for any inquiries or to get support for our solutions.",
      },
      { name: "twitter:image", content: "/contact-us.jpg" },
    ],
  },
};
</script>

<style scoped>
/* Hero Section */
.hero-section {
  position: relative;
  height: 450px;
}

.hero-image {
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Contact Form and Information */
.contact-us-page .card {
  border-radius: 10px;
}

.info-card {
  border: none;
}

h3 {
  font-weight: 700;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Map Section */
.mapouter {
  position: relative;
  text-align: right;
}

.gmap_canvas iframe {
  border: 0;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
</style>
