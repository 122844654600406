<template>
  <div :key="$route.fullPath">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <!-- Main Carousel -->
          <div
            id="carouselExampleCaptions"
            class="carousel slide mb-5"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="/p4.jpg"
                  class="d-block w-100"
                  alt="The Best Team At Your Service"
                  style="object-fit: cover; height: 45vh"
                />
                <div
                  class="carousel-caption d-none d-md-block bg-dark bg-opacity-75 p-3 rounded"
                >
                  <h5 class="text-white">Electrical Team at Work</h5>
                  <p class="text-light">
                    Delivering high-quality electrical solutions to our clients.
                  </p>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="/p5.jpg"
                  class="d-block w-100"
                  alt="IT Solutions"
                  style="object-fit: cover; height: 45vh"
                />
                <div
                  class="carousel-caption d-none d-md-block bg-dark bg-opacity-75 p-3 rounded"
                >
                  <h5 class="text-white">IT Solutions</h5>
                  <p class="text-light">
                    Providing innovative technology solutions to businesses.
                  </p>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="/p6.jpg"
                  class="d-block w-100"
                  alt="Solar energy system"
                  style="object-fit: cover; height: 45vh"
                />
                <div
                  class="carousel-caption d-none d-md-block bg-dark bg-opacity-75 p-3 rounded"
                >
                  <h5 class="text-white">Solar Energy Systems</h5>
                  <p class="text-light">
                    Harnessing the power of the sun for a sustainable future.
                  </p>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

          <!-- Info Section -->
          <div class="row mt-5">
            <div class="col-md-10 offset-md-1 text-center p-5">
              <h1 class="text-primary display-4 mb-4">
                Who is Excellent Systems
              </h1>
              <p class="fs-5 text-secondary">
                <strong><i>W</i></strong
                >elcome to Excellent Systems Company, established in 2010 in
                Ramallah, Palestine. We specialize in providing top-notch power
                solutions, communication systems, and information technology
                systems to the Palestinian market. Our mission is to deliver
                innovative and high-quality products that meet the evolving
                needs of our clients. With a focus on reliability and
                excellence, we are dedicated to empowering businesses and
                individuals with the best in technology and service. Join us in
                embracing the future, today.
              </p>
            </div>
          </div>

          <!-- Services Section -->
          <div class="row">
            <div class="col">
              <h1 class="text-primary mb-5 text-center">Our Services</h1>
            </div>
          </div>

          <!-- Service Cards -->
          <div class="row g-4">
            <div class="col-md-4">
              <div
                class="card shadow-lg h-100 text-center bg-light border-0 hover-shadow"
              >
                <div class="card-body">
                  <i
                    class="fa-solid fa-laptop-code fa-3xl mb-3 text-primary"
                  ></i>
                  <h5 class="card-title fs-4">IT Solutions</h5>
                  <p class="card-text text-muted">
                    Our IT Solutions streamline your business operations,
                    enhance productivity, and secure your digital
                    infrastructure. From custom software development to
                    comprehensive IT support, we provide the tools for your
                    business to thrive.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div
                class="card shadow-lg h-100 text-center bg-light border-0 hover-shadow"
              >
                <div class="card-body">
                  <i
                    class="fa-solid fa-solar-panel fa-3xl mb-3 text-success"
                  ></i>
                  <h5 class="card-title fs-4">Solar Energy Systems</h5>
                  <p class="card-text text-muted">
                    Embrace sustainable power with our state-of-the-art solar
                    energy solutions. Tailored to meet your specific needs,
                    ensuring reliable and clean energy for homes and businesses.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div
                class="card shadow-lg h-100 text-center bg-light border-0 hover-shadow"
              >
                <div class="card-body">
                  <i class="fa-solid fa-wifi fa-3xl mb-3 text-warning"></i>
                  <h5 class="card-title fs-4">Communication Systems</h5>
                  <p class="card-text text-muted">
                    Our communication systems ensure seamless connectivity for
                    your organization with advanced VoIP and robust networking
                    infrastructure, keeping you connected to the world.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Clients Section -->
          <div class="my-5">
            <h1 class="text-primary text-center my-5">Our Customers</h1>
            <div
              id="customersCarousel"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#customersCarousel"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Customer Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#customersCarousel"
                  data-bs-slide-to="1"
                  aria-label="Customer Slide 2"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <a
                        href="https://jawwal.ps"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/jawal.png"
                            class="card-img-top p-3"
                            alt="Client Jawwal"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://www.najah.edu"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/Najah.png"
                            class="card-img-top p-3"
                            alt="Client Najah"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://hebron.edu"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/Hebron.png"
                            class="card-img-top p-3"
                            alt="Client Hebron"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <a
                        href="https://www.actionagainsthunger.org/"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/action.png"
                            class="card-img-top p-3"
                            alt="Client Action"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://www.bethlehem-city.org/"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/Lahem.png"
                            class="card-img-top p-3"
                            alt="Client Lahem"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="http://www.hebron-city.ps/"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/Hebron.png"
                            class="card-img-top p-3"
                            alt="Client Hebron"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#customersCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#customersCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          <!-- Partners Section -->
          <div class="my-5 py-5">
            <h1 class="text-primary text-center my-5">Our Partners</h1>
            <div
              id="partnersCarousel"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#partnersCarousel"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Partner Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#partnersCarousel"
                  data-bs-slide-to="1"
                  aria-label="Partner Slide 2"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <a
                        href="https://www.acti.com/"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/ACTi.png"
                            class="card-img-top p-3"
                            alt="Partner ACTi"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://www.xerox.com"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/xerox1.png"
                            class="card-img-top p-3"
                            alt="Partner Xerox"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://www.akistechnologies.com/"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/akis1.png"
                            class="card-img-top p-3"
                            alt="Partner Akis"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <a
                        href="https://alpha.com"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/alpha1.png"
                            class="card-img-top p-3"
                            alt="Partner Alpha"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://victronenergy.com"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/victron.png"
                            class="card-img-top p-3"
                            alt="Partner Victron"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-md-4">
                      <a
                        href="https://ascot.com"
                        target="_blank"
                        class="card-link"
                      >
                        <div class="card text-center border-0">
                          <img
                            src="/ascot.png"
                            class="card-img-top p-3"
                            alt="Partner Ascot"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#partnersCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#partnersCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexPage",
};
</script>

<style scoped>
/* General styling for hover effects */
.card:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease-in-out;
}

.card img {
  transition: transform 0.3s ease-in-out;
}

.card:hover img {
  transform: scale(1.1);
}

.carousel-inner img {
  max-height: 150px;
  object-fit: contain;
}

.carousel-item {
  transition: transform 0.6s ease-in-out;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@media (max-width: 576px) {
  .carousel-inner img {
    width: 100%;
    max-height: 100px;
  }
}

.bg-light {
  background-color: #f8f9fa !important;
}
</style>
