<template>
  <div>
    <!-- Header Carousel -->
    <div class="container-fluid p-0">
      <div class="carousel slide" id="carouselExample" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="/p15.png" class="d-block w-100" alt="about us cover" />
            <div class="carousel-caption d-none d-md-block">
              <h1 class="display-4 fw-bold text-white">About Us</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mission Section -->
    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2 class="text-center text-primary display-5 mb-4">
            Our Mission <i class="fa-solid fa-compass"></i>
          </h2>
          <p class="fs-5 text-muted">
            Our mission is to empower the Palestinian market by delivering
            sophisticated solutions and leading-edge technologies in the realms
            of power systems, telecommunications, and information technology. We
            aim to enhance various sectors, from education and healthcare to
            communications and industry, driving growth and innovation
            throughout Palestine.
          </p>
        </div>
        <div class="col-md-6">
          <img src="/aue.jpg" class="img-fluid rounded shadow-lg" alt="CEO" />
        </div>
      </div>
    </div>

    <!-- Stats Section -->
    <div class="container-fluid bg-light py-5">
      <div class="row text-center">
        <div class="col-md-3">
          <h3 class="text-primary display-4 fw-bold">274</h3>
          <p class="text-muted">Clients</p>
        </div>
        <div class="col-md-3">
          <h3 class="text-primary display-4 fw-bold">421</h3>
          <p class="text-muted">Projects</p>
        </div>
        <div class="col-md-3">
          <h3 class="text-primary display-4 fw-bold">1.3K</h3>
          <p class="text-muted">Hours of Support</p>
        </div>
        <div class="col-md-3">
          <h3 class="text-primary display-4 fw-bold">10</h3>
          <p class="text-muted">Dedicated Employees</p>
        </div>
      </div>
    </div>

    <!-- Story Section -->
    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2 class="text-center text-primary display-5 mb-4">
            Our Story <i class="fa-solid fa-building"></i>
          </h2>
          <p class="fs-5 text-muted">
            Established in 2010 in Ramallah, Palestine, Excellent Systems
            Company specializes in power solutions, communication systems, and
            information technology for the Palestinian market. With a mission to
            deliver high-quality and reliable systems, we strive to set new
            standards for innovation and excellence in the region.
          </p>
        </div>
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-6">
              <img
                src="/p8.jpg"
                class="img-fluid rounded shadow-sm"
                alt="Image 1"
              />
            </div>
            <div class="col-6">
              <img
                src="/p9.jpg"
                class="img-fluid rounded shadow-sm"
                alt="Image 2"
              />
            </div>
            <div class="col-6">
              <img
                src="/p10.jpg"
                class="img-fluid rounded shadow-sm"
                alt="Image 3"
              />
            </div>
            <div class="col-6">
              <img
                src="/p11.jpg"
                class="img-fluid rounded shadow-sm"
                alt="Image 4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  metaInfo: {
    title: "About Us | Excellent Systems",
    meta: [
      {
        name: "description",
        content:
          "Learn more about Excellent Systems, a leader in power, IT, and solar energy solutions in Palestine.",
      },
      {
        name: "keywords",
        content:
          "About Excellent Systems, Power solutions, IT services, Solar energy systems",
      },
      { property: "og:title", content: "About Us | Excellent Systems" },
      {
        property: "og:description",
        content:
          "Discover the history and mission of Excellent Systems in providing power, IT, and solar energy solutions.",
      },
      { property: "og:image", content: "/about-us.jpg" },
      { property: "og:url", content: "https://yourdomain.com/about" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: "About Us | Excellent Systems" },
      {
        name: "twitter:description",
        content:
          "Our journey as a trusted provider of power, IT, and solar energy services in Palestine.",
      },
      { name: "twitter:image", content: "/about-us.jpg" },
    ],
  },
};
</script>

<style scoped>
/* Carousel Styles */
.carousel-inner img {
  height: 500px;
  object-fit: cover;
}

/* General Section Styling */
.container {
  max-width: 1200px;
}

h1,
h2 {
  font-weight: 700;
  margin-bottom: 1rem;
}

p {
  line-height: 1.6;
}

/* Responsive Image Grid */
.row.g-2 img {
  height: 250px;
  object-fit: cover;
}

/* Stats Section */
.bg-light {
  background-color: #f8f9fa !important;
}
</style>
