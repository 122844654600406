<template>
  <div id="app">
    <NavBar />
    <router-view /> <!-- This renders the component matched by the route -->
    <FooterBar />
  </div>
</template>

<script>
import NavBar from '@/components/nav-bar.vue';
import FooterBar from '@/components/footer-bar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.product-details {
  flex-grow: 1;
  padding: 20px;
}

footer {
  margin-top: auto;
}
</style>
