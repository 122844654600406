<template>
  <footer class="footer bg-dark text-white pt-5 pb-4">
    <div class="container">
      <!-- Footer Top -->
      <div class="row text-center text-md-start align-items-start">
        <!-- Logo and Social Media Section -->
        <div class="col-md-4 mb-4">
          <a href="/">
            <img
              src="/logo.png"
              alt="Excellent Systems Logo"
              class="img-fluid mb-3 logo"
            />
          </a>
          <div class="social-icons mt-3">
            <a href="#" class="text-white me-3 social-icon">
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="#" class="text-white me-3 social-icon">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="#" class="text-white me-3 social-icon">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="#" class="text-white social-icon">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </div>
        </div>

        <!-- Useful Links Section -->
        <div class="col-md-2 mb-4 d-flex flex-column align-items-start">
          <h5 class="text-uppercase">Useful Links</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <a href="/" class="text-white text-decoration-none footer-link"
                >Home</a
              >
            </li>
            <li class="mb-2">
              <a
                href="/about"
                class="text-white text-decoration-none footer-link"
                >About Us</a
              >
            </li>
            <li class="mb-2">
              <a
                href="/contact"
                class="text-white text-decoration-none footer-link"
                >Contact Us</a
              >
            </li>
          </ul>
        </div>

        <!-- Address Section -->
        <div class="col-md-3 mb-4 d-flex flex-column align-items-start">
          <h5 class="text-uppercase">Address</h5>
          <p class="text-white-50 mb-0">Al Falah Building, Al Quds St.</p>
          <p class="text-white-50 mb-0">Ramallah, Palestine</p>
        </div>

        <!-- Contact Info Section -->
        <div class="col-md-3 mb-4 d-flex flex-column align-items-start">
          <h5 class="text-uppercase">Contact Info</h5>
          <p class="text-white-50 mb-2">
            <strong>Phone:</strong> +970 2 2985198
          </p>
          <p class="text-white-50 mb-2"><strong>Fax:</strong> +970 2 2961469</p>
          <p class="text-white-50"><strong>Email:</strong> info@excellent.ps</p>
        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <p class="text-white-50 mb-0">
            &copy; 2024 Excellent Systems. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style scoped>
/* Footer Styles */
.footer {
  background: linear-gradient(135deg, #222, #1a1a1a);
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
}

/* Footer Logo Animation */
.footer .logo {
  max-width: 180px;
  animation: fadeInUp 1.5s ease-in-out;
}

/* Social Media Icons */
.footer .social-icons a {
  font-size: 1.5rem;
  display: inline-block;
  padding: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer .social-icons a:hover {
  transform: scale(1.2);
  color: #007bff;
}

/* Footer Links */
.footer h5 {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
}

.footer-link {
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #007bff;
}

/* Footer Bottom */
.footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Fade In Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .footer .row {
    text-align: center;
  }

  .footer .col-md-4,
  .footer .col-md-3,
  .footer .col-md-2 {
    margin-bottom: 2rem;
  }

  .footer .social-icons {
    justify-content: center;
  }
}
</style>
